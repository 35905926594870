import axios from 'axios'
import React from 'react'
import { useParams } from 'react-router-dom'
import copy from 'copy-to-clipboard'
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import IconButton from '@material-ui/core/IconButton'
import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import Fab from '@material-ui/core/Fab'
import Tooltip from '@material-ui/core/Tooltip'
import LinkIcon from '@material-ui/icons/Link'
import LinkOffIcon from '@material-ui/icons/LinkOff'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Snackbar from '@material-ui/core/Snackbar'

import * as Routes from 'routes'
import { Screener, ScreenerForm } from './ScreenerForm'
import { PublicScreenerPath } from './Routes'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      backgroundColor: theme.palette.background.paper
    },
    paper: {
      position: 'absolute',
      width: '60%',
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      overflow: 'scroll'
    }
  })
)

const Screeners: React.FunctionComponent<null> = () => {
  const { poolId } = useParams()
  const [screeners, setScreeners] = React.useState<Screener[]>([])
  const getScreeners = () => {
    axios.get(Routes.pool_screeners_path(poolId)).then((response) => {
      setScreeners(response.data)
    })
  }
  React.useEffect(getScreeners, [])

  const [isModalAddOpen, setIsModalAddOpen] = React.useState<boolean>(false)
  const [isModalEditOpen, setIsModalEditOpen] = React.useState<boolean>(false)
  const [formContent, setFormContent] = React.useState<Screener | null>(null)
  const [isCopyNotificationOpen, setIsCopyNotificationOpen] = React.useState<boolean>(false);
  const handleAddModal = () => {
    setIsModalAddOpen(true)
    setFormContent({ id: null, name: '', screener_questions: [], screener_links: [] })
  }
  const handleEditModal = (screener: Screener) => {
    setIsModalEditOpen(true)
    setFormContent(screener)
  }
  const handleCloseModal = () => {
    setIsModalAddOpen(false)
    setIsModalEditOpen(false)
    setFormContent(null)
  }
  const saveFunction = (screener: Screener) => {
    if (isModalAddOpen) {
      axios.post(Routes.pool_screeners_path(poolId), { screener: screener }).then(() => {
        getScreeners()
      })
    } else if (isModalEditOpen) {
      axios.patch(Routes.pool_screener_path(poolId, screener.id), { screener: screener }).then(() => {
        getScreeners()
      })
    } else {
      throw new Error('unimplemented')
    }
    handleCloseModal()
  }
  const deleteFunction = (screener: Screener) => {
    axios.delete(Routes.pool_screener_path(poolId, screener.id)).then(() => {
      getScreeners()
    })
  }

  const copyPublicLinkHandler = (screener: Screener) => {
    const path = PublicScreenerPath(screener.screener_links[0].key)
    const host = window.location.protocol + '//' + window.location.host
    const url = host + path
    copy(url)
    setIsCopyNotificationOpen(true)
  }
  const createPublicLinkHandler = (screener: Screener) => {
    axios.post(Routes.pool_screener_link_index_path(poolId, screener.id)).then(() => {
      getScreeners()
    })
  }
  const deletePublicLinkHandler = (screener: Screener) => {
    axios.delete(Routes.pool_screener_link_path(poolId, screener.id, screener.screener_links[0].id)).then(() => {
      getScreeners()
    })
  }
  const handleLinkCopiedNotificationClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsCopyNotificationOpen(false)
  };

  const classes = useStyles()

  return (
    <div className={classes.root}>
      <List component="nav" aria-label="screeners">
        {screeners.map((screener, idx) => {
          return (
            <ListItem key={idx}>
              <ListItemText primary={screener.name} />
              {
                screener.screener_links.length > 0
                  ? <React.Fragment>
                    <Tooltip title="Copy Link Text">
                      <IconButton id="copy_screener_link" aria-label="copy_screener_link" onClick={() => copyPublicLinkHandler(screener)}>
                        <LinkIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete Public Link">
                      <IconButton id="delete_screener_link" aria-label="delete_screener_link" onClick={() => deletePublicLinkHandler(screener)}>
                        <LinkOffIcon />
                      </IconButton>
                    </Tooltip>
                  </React.Fragment>
                  : <Tooltip title="Create Public Link">
                    <IconButton id="create_screener_link" aria-label="create_screener_link" onClick={() => createPublicLinkHandler(screener)}>
                      <LinkOffIcon />
                    </IconButton>
                  </Tooltip>
              }
              <IconButton aria-label="edit" id="edit_screener" onClick={() => handleEditModal(screener)}>
                <EditIcon />
              </IconButton>
              <IconButton aria-label="delete" id="delete_screener" onClick={() => deleteFunction(screener)}>
                <DeleteIcon />
              </IconButton>
            </ListItem>)
        })}
      </List>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={isCopyNotificationOpen}
        autoHideDuration={3000}
        onClose={handleLinkCopiedNotificationClose}
        message="Link copied"
      />
      <Fab color="primary" id="add_screener" aria-label="add" onClick={handleAddModal}>
        <AddIcon />
      </Fab>
      <Dialog
        open={isModalAddOpen || isModalEditOpen}
        onClose={handleCloseModal}
        scroll="paper"
        aria-labelledby="screener-form-title"
        aria-describedby="screener-form-content"
        maxWidth="lg"
        fullWidth={true}
      >
        <DialogTitle id="screener-form-title">Screener Form</DialogTitle>
        <DialogContent dividers={scroll === 'paper'}>
          <ScreenerForm initialScreener={formContent} onSave={saveFunction} onCancel={handleCloseModal}/>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default Screeners
