import React from 'react'
import IconButton from '@material-ui/core/IconButton'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'

import { ScreenerQuestion, ScreenerQuestionForm } from './ScreenerQuestionForm'

type OnChangeFunction = ([ScreenerQuestion]) => void;
type ScreenerQuestionsFormProps = {
  screenerQuestions: ScreenerQuestion[],
  onChange: OnChangeFunction,
};

export const ScreenerQuestionsForm: React.FunctionComponent<ScreenerQuestionsFormProps> = ({ screenerQuestions, onChange }: ScreenerQuestionsFormProps) => {
  const addQuestionHandler = () => {
    onChange([...screenerQuestions, { id: Date.now(), isNew: true, question_type: 'freetext_short', text: '', screener_options: [] }])
  }
  const removeQuestionHandler = (idx: number) => {
    const questions = [...screenerQuestions]
    questions.splice(idx, 1)
    onChange(questions)
  }
  const questionChangedHandler = (idx: number, screenerQuestion: ScreenerQuestion) => {
    const questions = [...screenerQuestions]
    questions[idx] = screenerQuestion
    onChange(questions)
  }

  return (
    <React.Fragment>
      {screenerQuestions.map((question, idx) => {
        return (<fieldset key={`question_form_${question.id}`}>
            <div className="question">
              <ScreenerQuestionForm screenerQuestion={question} onChange={(screenerQuestion => questionChangedHandler(idx, screenerQuestion))} />
              <IconButton aria-label="delete" color="secondary" onClick={() => removeQuestionHandler(idx)}>
                <DeleteIcon fontSize="small" />
              </IconButton>
            </div>
          </fieldset>)
      })}
      <IconButton id="add_question" aria-label="add question" color="secondary" onClick={addQuestionHandler}>
        <AddIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  )
}
