import React from 'react'
import camelCase from 'camelcase'
import FormLabel from '@material-ui/core/FormLabel'
import FormControl from '@material-ui/core/FormControl'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'

type OnChangeFunction = (string) => void;
type RadioButtonsOption = {
  value: string,
  label: string,
}
type RadioButtonsProps = {
  id: number,
  initialValue: string,
  label: string,
  options: RadioButtonsOption[],
  onChange: OnChangeFunction,
};

const RadioButtons: React.FunctionComponent<RadioButtonsProps> = ({ id, initialValue, label, options, onChange }: RadioButtonsProps) => {
  const key = camelCase(label)

  const setCurrentValueHandler = (event) => {
    const newValue = event.target.value
    onChange(newValue)
  }

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">{label}</FormLabel>
      <RadioGroup
        aria-label={`${key}_${id}_select_box_label`}
        name={`${key}_${id}_select_box`}
        onChange={setCurrentValueHandler}
      >
        {options.map((option) => {
          return <FormControlLabel key={option.value} value={option.value} control={<Radio checked={initialValue === option.value.toString()} />} label={option.label} />
        })}
      </RadioGroup>
    </FormControl>
  )
}

export default RadioButtons
