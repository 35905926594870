import axios from 'axios'
import React from 'react'
import { Redirect } from 'react-router-dom'
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles'
import TextField from './TextField'
import Button from '@material-ui/core/Button'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '25ch'
      }
    }
  })
)

const ProfileForm: React.FunctionComponent<null> = () => {
  const classes = useStyles()
  const [password, setPassword] = React.useState<string>("")
  const [password2, setPassword2] = React.useState<string>("")
  const [isDone, setIsDone] = React.useState<boolean>(false)
  const saveHandler = () => {
    if (password === password2) {
      axios.patch(Routes.current_user_path(), {user: {password: password}}).then(() => {
        setIsDone(true)
      })
    }
  }
  if (isDone) {
    return <Redirect to="/" />
  }

  return (
    <div>
      <form className={classes.root} onSubmit={saveHandler}>
        <TextField required id="password" type="password" label="Password" value={password} onChange={setPassword} />
        <TextField required id="password2" type="password" label="Password Repeat" value={password2} onChange={setPassword2} />
        <div>
          <Button variant="contained" color="primary" onClick={saveHandler}>
            Save
          </Button>
        </div>
      </form>
    </div>
  )
}

export default ProfileForm
