import axios from 'axios'
import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route
} from 'react-router-dom'
import { CookiesProvider, useCookies } from 'react-cookie'
import Container from '@material-ui/core/Container'
import CssBaseline from '@material-ui/core/CssBaseline'

import AppContext from './AppContext.tsx'
import Breadcrumbs from './Breadcrumbs'
import Header from './Header.tsx'
import Home from './Home.tsx'
import HomeInternal from './HomeInternal.tsx'
import LoginForm from './LoginForm.tsx'
import ProfileForm from './ProfileForm.tsx'
import Pools from './Pools.tsx'
import PublicScreenersForm from './PublicScreenersForm.tsx'
import Screeners from './Screeners.tsx'
import Participants from './Participants.tsx'

const SessionCookieName = 'janel-session'
const SessionCookieOptions = { path: '/', sameSite: 'strict', secure: true }

type LoggedInRouteProps = {
  isLoggedIn: boolean,
  props: Record<string, unknown>,
}
const LoginRoute: React.FunctionComponent<LoggedInRouteProps> = ({ isLoggedIn, ...props }: LoggedInRouteProps) => {
  return !isLoggedIn
    ? <Route { ...props } />
    : <Redirect to="/" />
}
const PrivateRoute: React.FunctionComponent<LoggedInRouteProps> = ({ isLoggedIn, ...props }: LoggedInRouteProps) => {
  return isLoggedIn
    ? <Route { ...props } />
    : <Redirect to="/login" />
}

const App: React.FunctionComponent<null> = () => {
  axios.defaults.headers.common.Accept = 'application/json'
  axios.defaults.headers.common['Content-Type'] = 'application/json'

  const [cookies, setCookie, removeCookie] = useCookies([SessionCookieName])
  const [userToken, setUserToken] = React.useState(cookies[SessionCookieName] || null)
  const [userInfo, setUserInfo] = React.useState(null)
  const onLogout = () => {
    removeCookie(SessionCookieName, SessionCookieOptions)
    setUserToken(null)
    setUserInfo(null)
  }
  axios.defaults.headers.common.Authorization = userToken
  axios.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response
  }, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.response.status == 401) {
      onLogout()
    }
    return Promise.reject(error)
  })
  const grabUserInfo = () => {
    if (userToken) {
      axios.get(Routes.current_user_path()).then((response) => {
        setUserInfo(response.data)
      })
    }
  }
  React.useEffect(grabUserInfo, [userToken])

  const globalState = {
    userToken: userToken,
    userInfo: userInfo
  }
  const successfulLoginHandler = (userToken, userInfo) => {
    setCookie(SessionCookieName, userToken, SessionCookieOptions)
    setUserInfo(userInfo)
    setUserToken(userToken)
  }
  const isLoggedIn = !!userToken

  return (
    <CookiesProvider>
      <AppContext.Provider value={globalState}>
        <Router>
          <React.Fragment>
            <CssBaseline />
            <Header onLogout={onLogout} />
            <Container maxWidth="md" m={2}>
              <React.Fragment>
                <Breadcrumbs />
                <Switch>
                  <PrivateRoute isLoggedIn={isLoggedIn} path="/pools/:poolId/participants">
                    <Participants />
                  </PrivateRoute>
                  <PrivateRoute isLoggedIn={isLoggedIn} path="/pools/:poolId/screeners">
                    <Screeners />
                  </PrivateRoute>
                  <PrivateRoute isLoggedIn={isLoggedIn} path="/pools">
                    <Pools />
                  </PrivateRoute>
                  <PrivateRoute isLoggedIn={isLoggedIn} path="/internal">
                    <HomeInternal />
                  </PrivateRoute>
                  <PrivateRoute isLoggedIn={isLoggedIn} path="/screeners/:key">
                    <PublicScreenersForm />
                  </PrivateRoute>
                  <LoginRoute isLoggedIn={isLoggedIn} path="/login">
                    <LoginForm onSuccess={successfulLoginHandler} />
                  </LoginRoute>
                  <PrivateRoute isLoggedIn={isLoggedIn} path="/profile">
                    <ProfileForm />
                  </PrivateRoute>
                  <PrivateRoute isLoggedIn={isLoggedIn} path="/">
                    <Home />
                  </PrivateRoute>
                </Switch>
              </React.Fragment>
            </Container>
          </React.Fragment>
        </Router>
      </AppContext.Provider>
    </CookiesProvider>
  )
}

export default App
