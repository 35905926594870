import React from 'react'
import camelCase from 'camelcase'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'

type OnChangeFunction = (string) => void;
type SelectBoxOption = {
  value: string,
  label: string,
}
type SelectBoxProps = {
  id: number,
  initialValue: string,
  label: string,
  options: SelectBoxOption[],
  onChange: OnChangeFunction,
};

const SelectBox: React.FunctionComponent<SelectBoxProps> = ({ id, initialValue, label, options, onChange }: SelectBoxProps) => {
  const key = camelCase(label)

  const setCurrentValueHandler = (event) => {
    const newValue = event.target.value
    onChange(newValue)
  }

  return (
    <FormControl>
      <InputLabel id={`${key}_${id}_select_box_label`}>{label}</InputLabel>
      <Select
        labelId={`${key}_${id}_select_box_label`}
        id={`${key}_${id}_select_box`}
        value={initialValue}
        onChange={setCurrentValueHandler}
      >
        {options.map((option) => {
          return <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
        })}
      </Select>
    </FormControl>
  )
}

export default SelectBox
