function toggleScreenerOptions (select) {
  const newQuestionType = select.value
  const questionOptions = select.closest('.field').nextElementSibling
  const questionTypesWithOptions = questionOptions.getAttribute('data-question-types-with-options').split(' ')
  if (questionTypesWithOptions.includes(newQuestionType)) {
    // un-remove all existing options
    Array.from(questionOptions.querySelectorAll('input[type=hidden][name*="[_destroy]"')).forEach((input) => {
      if (input) {
        input.value = 0
      }
    })
    // show options div
    questionOptions.style.display = 'inherit'
  } else {
    // remove all existing options
    Array.from(questionOptions.querySelectorAll('input[type=hidden][name*="[_destroy]"')).forEach((input) => {
      if (input) {
        input.value = 1
      }
    })
    // hide options div
    questionOptions.style.display = 'none'
  }
}

document.addEventListener('change', (e) => {
  const hasQuestionType = e.target.classList.contains('question_type')
  const closestForm = e.target.closest('form')
  const isScreenerForm = closestForm ? closestForm.classList.contains('screener_questions') : false
  if (isScreenerForm && hasQuestionType) {
    e.preventDefault()
    e.stopPropagation()
    toggleScreenerOptions(e.target)
  }
})
