import React from 'react'
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

import TextField from './TextField'
import { ScreenerQuestion } from './ScreenerQuestionForm'
import { ScreenerQuestionsForm } from './ScreenerQuestionsForm'

export type ScreenerLink = {
  id: number,
  key: string,
}
export type Screener = {
  id: number,
  name: string,
  screener_links: ScreenerLink[],
  screener_questions: ScreenerQuestion[],
};

type ScreenerSaveFunction = (screener: Screener) => void;
type CancelFunction = () => void;
type ScreenerFormProps = {
  initialScreener: Screener,
  onSave: ScreenerSaveFunction,
  onCancel: CancelFunction,
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        margin: theme.spacing(1)
      }
    }
  })
)

export const ScreenerForm: React.FunctionComponent<ScreenerFormProps> = ({ initialScreener, onSave, onCancel }: ScreenerFormProps) => {
  if (!initialScreener) return null
  const classes = useStyles()
  const [screener, setScreener] = React.useState<Screener>(initialScreener)
  const setScreenerNameHandler = (newName: string) => {
    setScreener({ ...screener, name: newName })
  }
  const setScreenerQuestionsHandler = (newQuestions: [ScreenerQuestion]) => {
    setScreener({ ...screener, screener_questions: newQuestions })
  }
  const convertToAttributes = (screener: Screener) => {
    const s = { id: screener.id, name: screener.name, screener_questions_attributes: [] }
    for (let i = 0; i < screener.screener_questions.length; i++) {
      s.screener_questions_attributes[i] = {
        question_type: screener.screener_questions[i].question_type,
        text: screener.screener_questions[i].text,
        screener_options_attributes: []
      }
      if (!screener.screener_questions[i].isNew) {
        s.screener_questions_attributes[i].id = screener.screener_questions[i].id
      }
      for (let j = 0; j < screener.screener_questions[i].screener_options.length; j++) {
        s.screener_questions_attributes[i].screener_options_attributes[j] = {
          text: screener.screener_questions[i].screener_options[j].text
        }
        if (!screener.screener_questions[i].screener_options[j].isNew) {
          s.screener_questions_attributes[i].screener_options_attributes[j].id = screener.screener_questions[i].screener_options[j].id
        }
      }
    }
    return s
  }
  const saveHandler = () => {
    onSave(convertToAttributes(screener))
  }

  return (
    <div>
      <form className={classes.root} onSubmit={saveHandler}>
        <TextField
        isRequired={true}
        id={screener.id}
        label="Screener Name"
        initialValue={screener.name}
        onChange={setScreenerNameHandler}
        />
        <Typography component="h3">
          Questions
        </Typography>
        <div className="questions">
          <ScreenerQuestionsForm
            screenerQuestions={screener.screener_questions}
            onChange={setScreenerQuestionsHandler}
          />
        </div>
        <div>
          <Button variant="contained" color="primary" onClick={saveHandler}>
            Save
          </Button>
          <Button variant="contained" color="secondary" onClick={onCancel}>
            Cancel
          </Button>
        </div>
      </form>
    </div>
  )
}
