import axios from 'axios'
import React from 'react'
import { Redirect } from 'react-router-dom'
import qs from 'qs'
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Snackbar from '@material-ui/core/Snackbar'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '25ch'
      }
    }
  })
)

export enum MagicTokenState {
  NotFound,
  Found,
  Failed,
}
type UserInfo = {
  id: integer,
  email: string,
}
type SetUserInfoFunction = (userToken: string, userInfo: UserInfo) => void;
type LoginFormProps = {
  onSuccess: SetUserInfoFunction,
};

const LoginForm: React.FunctionComponent<LoginFormProps> = ({ onSuccess }: LoginFormProps) => {
  const classes = useStyles()
  const magicLoginToken = qs.parse(window.location.search, { ignoreQueryPrefix: true }).magic_login_token
  const [magicTokenState, setMagicTokenState] = React.useState<string>(magicLoginToken ? MagicTokenState.Found : MagicTokenState.NotFound)
  const [email, setEmail] = React.useState<string>('')
  const [password, setPassword] = React.useState<string>('')
  const [isLinkSentNotificationOpen, setIsLinkSentNotificationOpen] = React.useState<boolean>(false);
  const setEmailHandler = (event) => {
    setEmail(event.target.value)
  }
  const setPasswordHandler = (event) => {
    setPassword(event.target.value)
  }
  const loginHandler = (response) => {
    const userToken = response.headers.authorization
    const userInfo = response.data
    onSuccess(userToken, userInfo)
  }
  const doLogin = (e) => {
    e.preventDefault()
    axios.post(Routes.new_user_session_path(), { user: { email: email, password: password } }).then((response) => {
      loginHandler(response)
    })
  }
  const requestLoginLink = () => {
    axios.post(Routes.magic_logins_path(), { user: { email: email } }).then(() => {
      setIsLinkSentNotificationOpen(true)
    })
  }
  const doMagicTokenLogin = () => {
    if (magicTokenState === MagicTokenState.Found) {
      axios.get(Routes.magic_logins_path(), { params: { magic_login_token: magicLoginToken } }).then((response) => {
        loginHandler(response)
      }).catch(function () {
        setMagicTokenState(MagicTokenState.Failed)
      })
    }
  }
  const handleLinkSentNotificationClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsLinkSentNotificationOpen(false)
  };
  React.useEffect(doMagicTokenLogin, [magicLoginToken])
  if (magicTokenState === MagicTokenState.Failed) {
    return <Redirect to="/" />
  } else if (magicTokenState === MagicTokenState.Found) {
    return null
  }

  return <React.Fragment>
    <form className={classes.root} onSubmit={doLogin}>
      <TextField required id="login_email" label="E-Mail" onChange={setEmailHandler} />
      <TextField required id="login_password" label="Password" type="password" onChange={setPasswordHandler} />
      <div>
        <Button variant="contained" color="primary" type="submit" onClick={doLogin}>
          Login
        </Button>
      </div>
      <div>
        <Button variant="contained" color="primary" type="button" onClick={requestLoginLink}>
          Request Login Link Via E-Mail
        </Button>
      </div>
    </form>
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={isLinkSentNotificationOpen}
      autoHideDuration={3000}
      onClose={handleLinkSentNotificationClose}
      message="Login link sent if user exists"
    />
  </React.Fragment>
}

export default LoginForm
