import axios from 'axios'
import React from 'react'
import { useParams } from 'react-router-dom'

import MaterialTable, { Column } from '@material-table/core'
import DeleteIcon from '@material-ui/icons/Delete'

import * as Routes from 'routes'
import { ScreenerQuestion } from './ScreenerQuestionForm'

export type Participant = {
  id: number;
  name: string;
  email: string;
  answers: Map<number, string | number | Array<string | number>>;
}

const baseColumns: Array<Column<Participant>> = [
  {
    field: 'name',
    title: 'Name',
    type: 'string'
  },
  {
    field: 'email',
    title: 'E-Mail',
    type: 'string'
  }
]

const DeleteIconComponent: React.FunctionComponent<null> = () => {
  return <DeleteIcon />
}

const Participants: React.FunctionComponent<null> = () => {
  const { poolId } = useParams()
  const [columns, setColumns] = React.useState<Array<Column<Participant>>>(baseColumns)
  const [participants, setParticipants] = React.useState<Participant[]>([])
  const getParticipants = () => {
    axios.get(Routes.pool_participants_path(poolId)).then((response) => {
      // set questions
      const newQuestions: Map<number, ScreenerQuestion> = {}
      response.data.screener_questions.forEach((q) => {
        newQuestions[q.id] = {
          id: q.id,
          isNew: false,
          question_type: q.question_type,
          text: q.text,
          screener_options: []
        }
      })

      // set columns
      const newColumns: Array<Column<Participant>> = baseColumns
      Object.keys(newQuestions).forEach((qId) => {
        newColumns.push({
          field: `q${qId}`,
          title: newQuestions[qId].text,
          type: 'string'
        })
      })
      setColumns(newColumns)

      // set participants
      const newParticipants: Participant[] = []
      response.data.participants.forEach((p) => {
        const answeredQuestions: Map<number, string | number> = {}
        const participantBase = {}
        p.screener_answers.forEach((a) => {
          const q = newQuestions[a.screener_question.id]
          const answer = Array.isArray(a.answer) ? a.answer.join(', ') : a.answer
          answeredQuestions[q.id] = answer
          participantBase[`q${q.id}`] = answer
        })
        newParticipants.push({
          ...participantBase,
          id: p.id,
          name: p.name,
          email: p.email,
          answers: answeredQuestions
        })
      })
      setParticipants(newParticipants)
    })
  }
  React.useEffect(getParticipants, [])

  const deleteParticipants = (ids: Array<number>) => {
    if (window.confirm(`Delete ${ids.length} participants?`)) {
      axios.delete(Routes.pool_participants_path(poolId), { data: { ids: ids } }).then(() => {
        getParticipants()
      })
    }
  }
  // const [select, setSelection] = React.useState<GridSelectionModel>([]);

  return (
    <div style={{ maxWidth: '100%' }}>
      <MaterialTable
        columns={columns}
        data={participants}
        title="Participants"
        options={{
          search: true,
          selection: true,
          sorting: true
        }}
        actions={[
          {
            icon: DeleteIconComponent,
            tooltip: 'Delete Participant',
            onClick: (event, rowData) => {
              deleteParticipants(rowData.map((r) => { return r.id }))
            }
          }
        ]}
      />
    </div>
  )
}

export default Participants
