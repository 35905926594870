import React from 'react'
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

import AppContext from './AppContext'
import { PoolsPath } from './Routes'

const HomeInternal: React.FunctionComponent<null> = () => {
  const appContext = React.useContext(AppContext)
  if (!appContext.userInfo) {
    return null
  }

  return (
    <div>
      <Typography component="h1">
        Hello {appContext.userInfo.email}!
      </Typography>
      <Button variant="contained" component={Link} to={PoolsPath}>
        Pools
      </Button>
    </div>
  )
}

export default HomeInternal
