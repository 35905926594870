import axios from 'axios'
import React from 'react'
import { useParams } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'

import * as Routes from 'routes'
import { Screener } from './ScreenerForm'
import { ScreenerQuestionType } from './ScreenerQuestionForm'
import { ScreenerOption } from './ScreenerOptionForm'
import Checkboxes from './Checkboxes'
import RadioButtons from './RadioButtons'
import TextField from './TextField'
import TextArea from './TextArea'

type ScreenerAnswer = {
  screener_question_id: number,
  screener_question_type: string,
  screener_question_text: string,
  screener_options: ScreenerOption[],
  freetext_short: string,
  freetext_long: string,
  chosen_options: string[],
};

const PublicScreenersForm: React.FunctionComponent<null> = () => {
  const { key } = useParams()
  const [screener, setScreener] = React.useState<Screener>(null)
  const [participantName, setParticipantName] = React.useState<string>('')
  const [participantEmail, setParticipantEmail] = React.useState<string>('')
  const [answers, setAnswers] = React.useState<ScreenerAnswer[]>([])
  const [done, setDone] = React.useState<boolean>(false)
  const getScreener = () => {
    axios.get(Routes.screener_index_path(key)).then((response) => {
      setScreener(response.data)
      const tmp = []
      for (let i = 0; i < response.data.screener_questions.length; i++) {
        tmp.push({
          screener_question_id: response.data.screener_questions[i].id,
          screener_question_type: response.data.screener_questions[i].question_type,
          screener_question_text: response.data.screener_questions[i].text,
          screener_options: response.data.screener_questions[i].screener_options,
          freetext_short: '',
          freetext_long: '',
          chosen_options: []
        })
      }
      setAnswers(tmp)
    })
  }
  React.useEffect(getScreener, [])

  const setFreetextShortAnswer = (idx: number, answer: string) => {
    const newAnswers = [...answers]
    newAnswers[idx].freetext_short = answer
    setAnswers(newAnswers)
  }
  const setFreetextLongAnswer = (idx: number, answer: string) => {
    const newAnswers = [...answers]
    newAnswers[idx].freetext_long = answer
    setAnswers(newAnswers)
  }
  const setRadioAnswer = (idx: number, option: string) => {
    const newAnswers = [...answers]
    newAnswers[idx].chosen_options = [option]
    setAnswers(newAnswers)
  }
  const setCheckboxAnswer = (idx: number, options: string[]) => {
    const newAnswers = [...answers]
    newAnswers[idx].chosen_options = options
    setAnswers(newAnswers)
  }

  if (done) {
    return <Box>Thank you</Box>
  }
  if (!screener) {
    return null
  }

  const convertToSave = () => {
    return {
      participant_attributes: {
        name: participantName,
        email: participantEmail
      },
      screener_answers_attributes: answers.map((answer) => {
        const tmp = {
          screener_question_id: answer.screener_question_id
        }
        if (answer.screener_question_type === ScreenerQuestionType.FreetextShort) {
          tmp.freetext_short = answer.freetext_short
        } else if (answer.screener_question_type === ScreenerQuestionType.FreetextLong) {
          tmp.freetext_long = answer.freetext_long
        } else if (answer.screener_question_type === ScreenerQuestionType.Radio) {
          tmp.screener_option_id = answer.chosen_options[0]
        } else if (answer.screener_question_type === ScreenerQuestionType.Checkbox) {
          tmp.screener_option_ids = answer.chosen_options
        } else {
          throw new Error()
        }
        return tmp
      })
    }
  }
  const saveHandler = () => {
    const apiPayload = convertToSave()
    console.log(apiPayload)
    axios.post(Routes.screener_index_path(key), { screener_attempt: apiPayload }).then(() => {
      setDone(true)
    })
  }

  return (
    <form onSubmit={saveHandler}>
      <Box m={2}>
        <TextField
          id="participant_name"
          label="Your Name"
          initialValue={participantName}
          onChange={setParticipantName}
        />
      </Box>
      <Box m={2}>
        <TextField
          id="participant_email"
          label="Your E-Mail"
          initialValue={participantEmail}
          onChange={setParticipantEmail}
        />
      </Box>
      {
      answers.map((answer, idx) => {
        if (answer.screener_question_type === 'freetext_short') {
          return <Box key={answer.screener_question_id} id={`question_${answer.screener_question_id}`} m={2}>
              <TextField
                id={answer.screener_question_id}
                label={answer.screener_question_text}
                initialValue={answer.freetext_short}
                onChange={(newValue) => setFreetextShortAnswer(idx, newValue)}
              />
            </Box>
        } else if (answer.screener_question_type === 'freetext_long') {
          return <Box key={answer.screener_question_id} id={`question_${answer.screener_question_id}`} m={2}>
              <TextArea
                id={answer.screener_question_id}
                label={answer.screener_question_text}
                initialValue={answer.freetext_long}
                onChange={(newValue) => setFreetextLongAnswer(idx, newValue)}
              />
            </Box>
        } else if (answer.screener_question_type === 'checkbox') {
          return <Box key={answer.screener_question_id} id={`question_${answer.screener_question_id}`} m={2}>
              <Checkboxes
                id={answer.screener_question_id}
                label={answer.screener_question_text}
                initialValue={answer.chosen_options}
                options={answer.screener_options.map((option) => { return { label: option.text, value: option.id } })}
                onChange={(newValue) => setCheckboxAnswer(idx, newValue)}
              />
            </Box>
        } else if (answer.screener_question_type === 'radio') {
          return <Box key={answer.screener_question_id} id={`question_${answer.screener_question_id}`} m={2}>
              <RadioButtons
                id={answer.screener_question_id}
                label={answer.screener_question_text}
                initialValue={answer.chosen_options[0]}
                options={answer.screener_options.map((option) => { return { label: option.text, value: option.id } })}
                onChange={(newValue) => setRadioAnswer(idx, newValue)}
              />
            </Box>
        } else {
          throw new Error('not implemented')
        }
      })
    }
      <div>
        <Button variant="contained" color="primary" onClick={saveHandler}>
          Save
        </Button>
      </div>
    </form>
  )
}

export default PublicScreenersForm
