import React from 'react'
import FormLabel from '@material-ui/core/FormLabel'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'

type OnChangeFunction = (string) => void;
type CheckBoxesOption = {
  value: string,
  label: string,
}
type CheckBoxesProps = {
  initialValue: string[],
  label: string,
  options: CheckBoxesOption[],
  onChange: OnChangeFunction,
};

const CheckBoxes: React.FunctionComponent<CheckBoxesProps> = ({ initialValue, label, options, onChange }: CheckBoxesProps) => {
  const [currentValues, setCurrentValues] = React.useState<string[]>(initialValue)

  const setCurrentValuesHandler = (event) => {
    const newValue = event.target.name
    const index = currentValues.indexOf(newValue)
    const newValues = [...currentValues]
    if (index > -1) {
      newValues.splice(index, 1)
    } else {
      newValues.push(newValue)
    }
    setCurrentValues(newValues)
    onChange(newValues)
  }

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">{label}</FormLabel>
      <FormGroup>
        {options.map((option) => {
          return <FormControlLabel key={option.value} label={option.label} control={
            <Checkbox
              checked={currentValues.includes(option.value.toString())}
              onChange={setCurrentValuesHandler}
              name={option.value.toString()}
            />}
          />
        })}
      </FormGroup>
    </FormControl>
  )
}

export default CheckBoxes
