import React from 'react'

import SelectBox from './SelectBox'
import TextField from './TextField'
import { ScreenerOption } from './ScreenerOptionForm'
import { ScreenerOptionsForm } from './ScreenerOptionsForm'

export enum ScreenerQuestionType {
  FreetextShort = 'freetext_short',
  FreetextLong = 'freetext_long',
  Radio = 'radio',
  Checkbox = 'checkbox',
}
export type ScreenerQuestion = {
  id: number,
  isNew: boolean,
  question_type: ScreenerQuestionType,
  text: string,
  screener_options: ScreenerOption[],
}
type OnChangeFunction = (ScreenerQuestion) => void;
type ScreenerQuestionFormProps = {
  screenerQuestion: ScreenerQuestion,
  onChange: OnChangeFunction,
};

export const ScreenerQuestionForm: React.FunctionComponent<ScreenerQuestionFormProps> = ({ screenerQuestion, onChange }: ScreenerQuestionFormProps) => {
  const hasOptions = (screenerQuestion.question_type === 'radio' || screenerQuestion.question_type === 'checkbox')

  const setTextHandler = (newText) => {
    onChange({ ...screenerQuestion, text: newText })
  }
  const setTypeHandler = (newType) => {
    onChange({ ...screenerQuestion, question_type: newType })
  }
  const setScreenerOptionsHandler = (newScreenerOptions: ScreenerOption[]) => {
    onChange({ ...screenerQuestion, screener_options: newScreenerOptions })
  }

  return (
    <React.Fragment>
      <TextField
        isRequired={true}
        id={screenerQuestion.id}
        label="Question Text"
        initialValue={screenerQuestion.text}
        onChange={setTextHandler}
      />
      <span className="question_type">
        <SelectBox
          isRequired={true}
          id={screenerQuestion.id}
          label="Question Type"
          initialValue={screenerQuestion.question_type}
          onChange={setTypeHandler}
          options={[
            { label: 'Freetext short', value: 'freetext_short' },
            { label: 'Freetext long', value: 'freetext_long' },
            { label: 'Radio', value: 'radio' },
            { label: 'Checkbox', value: 'checkbox' }
          ]}
        />
      </span>
      {!hasOptions ? null : <div className="options"><ScreenerOptionsForm screenerOptions={screenerQuestion.screener_options} onChange={setScreenerOptionsHandler} /></div>}
    </React.Fragment>
  )
}
