import React from 'react'
import camelCase from 'camelcase'
import MaterialTextField from '@material-ui/core/TextField'

type OnChangeFunction = (string) => void;
type TextFieldProps = {
  id: number,
  initialValue: string,
  label: string,
  isRequired: boolean,
  onChange: OnChangeFunction,
  type: string,
};

const TextField: React.FunctionComponent<TextFieldProps> = ({ id, initialValue, label, isRequired, onChange, type }: TextFieldProps) => {
  const key = camelCase(label)

  const setCurrentValueHandler = (event) => {
    const newValue = event.target.value
    onChange(newValue)
  }

  return (
    <MaterialTextField
      required={isRequired}
      id={`${key}_${id}_text_field`}
      label={label}
      value={initialValue}
      onChange={setCurrentValueHandler}
      type={type || "string"}
    />
  )
}

export default TextField
