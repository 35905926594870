import axios from 'axios'
import React from 'react'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import Drawer from '@material-ui/core/Drawer'
import { makeStyles } from '@material-ui/core/styles'
import Link from '@material-ui/core/Link'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import HomeIcon from '@material-ui/icons/Home'
import HomeWorkIcon from '@material-ui/icons/HomeWork'
import PoolIcon from '@material-ui/icons/Pool'
import Box from '@material-ui/core/Box'

import AppContext from './AppContext'
import { RootPath, InternalPath, LoginPath, PoolsPath, ProfilePath } from './Routes'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1
  }
}))

type OnLogoutFunction = () => void;
type HeaderProps = {
  onLogout: OnLogoutFunction,
};

const Header: React.FunctionComponent<HeaderProps> = ({onLogout}: HeaderProps) => {
  const location = useLocation()
  const classes = useStyles()
  const appContext = React.useContext(AppContext)
  const [isMenuOpen, setIsMenuOpen] = React.useState(false)
  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return
    }
    setIsMenuOpen(open)
  }
  const doLogout = () => {
    axios.delete(Routes.destroy_user_session_path()).then(() => {
      onLogout()
    }).catch(function (error) {
      console.error(error)
    })
  }
  const isSignedIn = appContext.userInfo && appContext.userToken
  const profileLink = isSignedIn
    ? <Button color="inherit" component={RouterLink} to={ ProfilePath }>Profile</Button>
    : null
  const userLink = isSignedIn
    ? <Button color="inherit" onClick={doLogout}>Logout</Button>
    : <Button color="inherit" component={RouterLink} to={ LoginPath }>Login</Button>

  if (location.pathname.startsWith('/screeners/')) {
    return <Box mb={2}></Box>
  }

  return (
    <Box mb={2}>
      <div className={classes.root}>
        <AppBar position="static">
          <Toolbar>
            <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              <Link component={RouterLink} color="inherit" to={ RootPath }>
                Home
              </Link>
            </Typography>
            {profileLink}
            {userLink}
          </Toolbar>
        </AppBar>
        <Drawer anchor="left" open={isMenuOpen} onClose={toggleDrawer(false)}>
          <div
            className={classes.list}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
          >
            <List>
              <ListItem button key="Home" component={RouterLink} to={ RootPath }>
                <ListItemIcon><HomeIcon /></ListItemIcon>
                <ListItemText primary="Home" />
              </ListItem>
              <ListItem button key="Home Internal" component={RouterLink} to={ InternalPath }>
                <ListItemIcon><HomeWorkIcon /></ListItemIcon>
                <ListItemText primary="Home Internal" />
              </ListItem>
              <ListItem button key="Pools" component={RouterLink} to={ PoolsPath }>
                <ListItemIcon><PoolIcon /></ListItemIcon>
                <ListItemText primary="Pools" />
              </ListItem>
            </List>
          </div>
        </Drawer>
      </div>
    </Box>
  )
}

export default Header
