import React from 'react'
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { InternalPath } from './Routes'

const Home: React.FunctionComponent<null> = () => <div>
  <Typography component="h1">
    Hello World!
  </Typography>
  <Button variant="contained" component={Link} to={InternalPath}>
    Client Area
  </Button>
</div>

export default Home
