import React from 'react'
import IconButton from '@material-ui/core/IconButton'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'

import { ScreenerOption, ScreenerOptionForm } from './ScreenerOptionForm'

type OnChangeFunction = ([ScreenerOption]) => void;
type ScreenerOptionsFormProps = {
  screenerOptions: ScreenerOption[],
  onChange: OnChangeFunction,
};

export const ScreenerOptionsForm: React.FunctionComponent<ScreenerOptionsFormProps> = ({ screenerOptions, onChange }: ScreenerOptionsFormProps) => {
  const addOptionHandler = () => {
    onChange([...screenerOptions, { id: Date.now(), isNew: true, text: '' }])
  }
  const removeOptionHandler = (idx: number) => {
    const options = [...screenerOptions]
    options.splice(idx, 1)
    onChange(options)
  }
  const optionChangedHandler = (idx: number, screenerOption: ScreenerOption) => {
    const questions = [...screenerOptions]
    questions[idx] = screenerOption
    onChange(questions)
  }

  return (
    <React.Fragment>
      {screenerOptions.map((option, idx) => {
        return (<fieldset key={`option_form_${option.id}`}>
            <div className="option">
              <ScreenerOptionForm screenerOption={option} onChange={(screenerOption) => optionChangedHandler(idx, screenerOption)} />
            </div>
            <IconButton aria-label="delete" color="secondary" onClick={() => removeOptionHandler(idx)}>
              <DeleteIcon fontSize="small" />
            </IconButton>
          </fieldset>)
      })}
      <IconButton id="add_option" aria-label="add option" color="secondary" onClick={addOptionHandler}>
        <AddIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  )
}
