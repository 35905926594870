import React from 'react'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import Link from '@material-ui/core/Link'
import HomeIcon from '@material-ui/icons/Home'
import HomeWorkIcon from '@material-ui/icons/HomeWork'
import PoolIcon from '@material-ui/icons/Pool'
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer'
import PeopleIcon from '@material-ui/icons/People'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'

import { RootPath, LoginPath, InternalPath, PoolsPath, ProfilePath } from './Routes'

const rootClasses = {root: 'breadcrumbs'}
const currentClasses = {root: 'current'}

const AppBreadcrumbs: React.FunctionComponent<null> = () => {
  const location = useLocation()
  if (location.pathname === RootPath || location.pathname === LoginPath || location.pathname === ProfilePath) {
    return (
      <Box mb={1}>
        <Breadcrumbs aria-label="breadcrumb" classes={rootClasses}>
          <Typography color="textPrimary" classes={currentClasses}>
            <HomeIcon fontSize="small" /> Home
          </Typography>
        </Breadcrumbs>
        <Divider />
      </Box>)
  } else if (location.pathname === InternalPath) {
    return (
      <Box mb={1}>
        <Breadcrumbs aria-label="breadcrumb" classes={rootClasses}>
          <Link component={RouterLink} color="inherit" to={RootPath}>
            <HomeIcon fontSize="small" /> Home
          </Link>
          <Typography color="textPrimary" classes={currentClasses}>
            <HomeWorkIcon fontSize="small" /> Home Internal
          </Typography>
        </Breadcrumbs>
        <Divider />
      </Box>)
  } else if (location.pathname === PoolsPath) {
    return (
      <Box mb={1}>
        <Breadcrumbs aria-label="breadcrumb" classes={rootClasses}>
          <Link component={RouterLink} color="inherit" to={RootPath}>
            <HomeIcon fontSize="small" /> Home
          </Link>
          <Link component={RouterLink} color="inherit" to={InternalPath}>
            <HomeWorkIcon fontSize="small" /> Home Internal
          </Link>
          <Typography color="textPrimary" classes={currentClasses}>
            <PoolIcon fontSize="small" /> Pools
          </Typography>
        </Breadcrumbs>
        <Divider />
      </Box>)
  } else if (location.pathname.startsWith(PoolsPath) && location.pathname.endsWith('screeners')) {
    return (
      <Box mb={1}>
        <Breadcrumbs aria-label="breadcrumb" classes={rootClasses}>
          <Link component={RouterLink} color="inherit" to={RootPath}>
            <HomeIcon fontSize="small" /> Home
          </Link>
          <Link component={RouterLink} color="inherit" to={InternalPath}>
            <HomeWorkIcon fontSize="small" /> Home Internal
          </Link>
          <Link component={RouterLink} color="inherit" to={PoolsPath}>
            <PoolIcon fontSize="small" /> Pools
          </Link>
          <Typography color="textPrimary" classes={currentClasses}>
            <QuestionAnswerIcon fontSize="small" /> Screeners
          </Typography>
        </Breadcrumbs>
        <Divider />
      </Box>)
  } else if (location.pathname.startsWith(PoolsPath) && location.pathname.endsWith('participants')) {
    return (
      <Box mb={1}>
        <Breadcrumbs aria-label="breadcrumb" classes={rootClasses}>
          <Link component={RouterLink} color="inherit" to={RootPath}>
            <HomeIcon fontSize="small" /> Home
          </Link>
          <Link component={RouterLink} color="inherit" to={InternalPath}>
            <HomeWorkIcon fontSize="small" /> Home Internal
          </Link>
          <Link component={RouterLink} color="inherit" to={PoolsPath}>
            <PoolIcon fontSize="small" /> Pools
          </Link>
          <Typography color="textPrimary" classes={currentClasses}>
            <PeopleIcon fontSize="small" /> Participants
          </Typography>
        </Breadcrumbs>
        <Divider />
      </Box>)
  } else if (location.pathname.startsWith('/screeners/')) {
    return null
  } else {
    throw new Error('not implemented')
  }
}

export default AppBreadcrumbs
