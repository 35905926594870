import React from 'react'
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'

export type Pool = {
  id: number;
  name: string;
};

type PoolSaveFunction = (pool: Pool) => void;
type CancelFunction = () => void;
type PoolFormProps = {
  pool: Pool;
  onSave: PoolSaveFunction,
  onCancel: CancelFunction,
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '25ch'
      }
    }
  })
)

export const PoolForm: React.FunctionComponent<PoolFormProps> = ({ pool, onSave, onCancel }: PoolFormProps) => {
  const classes = useStyles()
  const [poolName, setPoolName] = React.useState<string>(pool.name)
  const setPoolNameHandler = (event) => {
    setPoolName(event.target.value)
  }
  const saveHandler = () => {
    onSave({ id: pool.id, name: poolName })
  }

  return (
    <div>
      <form className={classes.root} onSubmit={saveHandler}>
        <TextField required id="pool_name" label="Pool Name" value={poolName} onChange={setPoolNameHandler} />
        <div>
          <Button variant="contained" color="primary" onClick={saveHandler}>
            Save
          </Button>
          <Button variant="contained" color="secondary" onClick={onCancel}>
            Cancel
          </Button>
        </div>
      </form>
    </div>
  )
}
