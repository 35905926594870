import React from 'react'
import TextField from './TextField'

export type ScreenerOption = {
  id: number,
  isNew: boolean,
  text: string,
}
type OnChangeFunction = (string) => void;
type ScreenerOptionFormProps = {
  screenerOption: ScreenerOption,
  onChange: OnChangeFunction,
};

export const ScreenerOptionForm: React.FunctionComponent<ScreenerOptionFormProps> = ({ screenerOption, onChange }: ScreenerOptionFormProps) => {
  const setTextHandler = (newText) => {
    onChange({ ...screenerOption, text: newText })
  }

  return (
    <TextField
      isRequired={true}
      label="Option Text"
      id={screenerOption.id}
      initialValue={screenerOption.text}
      onChange={setTextHandler}
    />
  )
}
