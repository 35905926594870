import axios from 'axios'
import React from 'react'
import { Link } from 'react-router-dom'
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import IconButton from '@material-ui/core/IconButton'
import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import PeopleIcon from '@material-ui/icons/People'
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer'
import Fab from '@material-ui/core/Fab'
import Modal from '@material-ui/core/Modal'

import * as Routes from 'routes'
import { Pool, PoolForm } from './PoolForm'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      backgroundColor: theme.palette.background.paper
    },
    paper: {
      position: 'absolute',
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3)
    }
  })
)

const modalStyle = {
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)'
}

const Pools: React.FunctionComponent<null> = () => {
  const [pools, setPools] = React.useState<Pool[]>([])
  const getPools = () => {
    axios.get(Routes.pools_path()).then((response) => {
      setPools(response.data)
    })
  }
  React.useEffect(getPools, [])

  const [isModalAddOpen, setIsModalAddOpen] = React.useState<boolean>(false)
  const [isModalEditOpen, setIsModalEditOpen] = React.useState<boolean>(false)
  const [formContent, setFormContent] = React.useState<Pool | null>(null)
  const handleAddModal = () => {
    setIsModalAddOpen(true)
    setFormContent({ id: null, name: '' })
  }
  const handleEditModal = (pool: Pool) => {
    setIsModalEditOpen(true)
    setFormContent(pool)
  }
  const handleCloseModal = () => {
    setIsModalAddOpen(false)
    setIsModalEditOpen(false)
    setFormContent(null)
  }
  const saveFunction = (pool: Pool) => {
    if (isModalAddOpen) {
      axios.post(Routes.pools_path(), { pool: pool }).then(() => {
        getPools()
      })
    } else if (isModalEditOpen) {
      axios.patch(Routes.pool_path(pool.id), { pool: pool }).then(() => {
        getPools()
      })
    } else {
      throw new Error('unimplemented')
    }
    handleCloseModal()
  }
  const deleteFunction = (pool: Pool) => {
    axios.delete(Routes.pool_path(pool.id)).then(() => {
      getPools()
    })
  }

  const classes = useStyles()

  return (
    <div className={classes.root}>
      <List component="nav" aria-label="pools">
        {pools.map((pool, idx) => {
          return (
            <ListItem key={idx}>
              <ListItemText primary={pool.name} />
              <IconButton aria-label="screeners" component={Link} to={`/pools/${pool.id}/screeners`}>
                <QuestionAnswerIcon />
              </IconButton>
              <IconButton aria-label="participants" component={Link} to={`/pools/${pool.id}/participants`}>
                <PeopleIcon />
              </IconButton>
              <IconButton aria-label="edit" id="edit_pool" onClick={() => handleEditModal(pool)}>
                <EditIcon />
              </IconButton>
              <IconButton aria-label="delete" id="delete_pool" onClick={() => deleteFunction(pool)}>
                <DeleteIcon />
              </IconButton>
            </ListItem>)
        })}
      </List>
      <Fab color="primary" id="add_pool" aria-label="add" onClick={handleAddModal}>
        <AddIcon />
      </Fab>
      <Modal
        open={isModalAddOpen || isModalEditOpen}
        onClose={handleCloseModal}
        aria-labelledby="pool-form-title"
        aria-describedby="pool-form-content"
      >
        <div style={modalStyle} className={classes.paper}>
          <h2 id="pool-form-title">Pool Form</h2>
          <div id="pool-form-content">
            <PoolForm pool={formContent} onSave={saveFunction} onCancel={handleCloseModal}/>
          </div>
        </div>
      </Modal>
      </div>
  )
}

export default Pools
